@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

@font-face {
    font-family: "AvertaStd";
    font-weight: 100;
    src: url("../fonts/AvertaStd-Extrathin.ttf") format("truetype")
}

@font-face {
    font-family: "AvertaStd";
    font-weight: 200;
    src: url("../fonts/AvertaStd-Thin.ttf") format("truetype")
}

@font-face {
    font-family: "AvertaStd";
    font-weight: 300;
    src: url("../fonts/AvertaStd-Light.ttf") format("truetype")
}

@font-face {
    font-family: "AvertaStd";
    font-weight: 400;
    src: url("../fonts/AvertaStd-Regular.ttf") format("truetype")
}

@font-face {
    font-family: "AvertaStd";
    font-weight: 600;
    src: url("../fonts/AvertaStd-Semibold.ttf") format("truetype")
}

@font-face {
    font-family: "AvertaStd";
    font-weight: 700;
    src: url("../fonts/AvertaStd-Bold.ttf") format("truetype")
}

@font-face {
    font-family: "AvertaStd";
    font-weight: 800;
    src: url("../fonts/AvertaStd-ExtraBold.ttf") format("truetype")
}

@font-face {
    font-family: 'Circular';
    src: url(../fonts/circular/CircularStd-Light.otf) format("opentype");
    // src: url(../fonts/circular/CircularXXWeb-Light.woff2) format("woff2");
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: 'Circular';
    src: url(../fonts/circular/CircularXXWeb-Regular.woff2) format("woff2");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Circular';
    src: url(../fonts/circular/CircularStd-Book.otf) format("opentype");
    font-style: normal;
    font-weight: 500
}

@font-face {
    font-family: 'Circular';
    // src: url(../fonts/circular/CircularStd-Medium.otf) format("opentype");
    src: url(../fonts/circular/CircularXXWeb-Medium.woff2) format("woff2");
    font-style: normal;
    font-weight: 600
}

@font-face {
    font-family: 'Circular';
    src: url(../fonts/circular/CircularStd-Bold.otf) format("opentype");
    // src: url(../fonts/circular/CircularXXWeb-Bold.woff2) format("woff2");
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'Circular';
    src: url(../fonts/circular/CircularStd-Black.otf) format("opentype");
    // src: url(../fonts/circular/CircularXXWeb-Black.woff2) format("woff2");
    font-style: normal;
    font-weight: 900;
}